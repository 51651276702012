<template>
    <v-app>
        <div class="frame-popup">
            <header>
                <h1>반대의사</h1>
                <div class="is-right">
                    <v-btn class="jh-btn is-icon" title="닫기" @click="close"><i class="jh-icon-close-lg is-white"></i></v-btn>
                </div>
            </header>
            <main><T100301></T100301></main>
        </div>        
    </v-app>
</template>

<script>
import { mixin } from "@/mixin/mixin.js";
import T100301 from "./T100301.vue";
  
export default {
    name: "MENU_PT100301", //name은 'MENU_' + 파일명 조합
    mixins: [mixin],
    components: {
        T100301
    },
    data() {
        return {
        }
    },
    computed: {
    },
    methods: {
        close(){
            self.close();
        },        
    },
    created() { 
    },
    mounted() {
        // 토큰 동기화를 위해 메인창에 window 객체에 팝업 windows 객체 저장
        // window 팝업에서 palette api를 호출할 경우 필수
        if (opener) {
            if (!opener.popups) opener.popups = [];
                opener.popups.push(self);
        }
    }
};
</script>

<style></style>  